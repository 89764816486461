// VARS
var body = $('body');

$(document).ready(function(){

	siteInit.soundtrack();
	siteInit.customCursor();
	siteInit.hoverSplitText();
	siteInit.gameTrigger();
	siteInit.gameRecord();
	siteInit.share();
	siteInit.loader();
	siteInit.gsapTimelineGameover();
	siteInit.gsapTextSplitted();
	siteInit.tutorial();

	$('.js-prevent-default').click(function(e){
		e.preventDefault();
	});

});

var siteInit = {

	tutorial: function() {

		var $el = $('.js-tutorial-back');

		if ($el.length == 0) {
			return;
		}

		$($el).click(function(){
			$('.js-controller-select').show();
			$('.js-tutorial').hide();
		});

	},

	gsapTextSplitted: function() {

		var $el = $('.js-splittext');

		if ($el.length == 0) {
			return;
		}

		var mySplitText = new SplitText($el, {type: 'words, chars'});

	},

	loader: function() {

		TweenMax.delayedCall(.5, loaderAnimation);

		function loaderAnimation() {

			TweenMax.to('.js-loader__sprite', 1.5, { opacity: 1, y: 0, ease: Back.easeInOut.config(1.7) });
			TweenMax.to('.js-loader__spriteimg',1.1, {repeat: -1,x: -2250,ease: SteppedEase.config(15)});

		}

		$(body).on('managerLoaded', function() {

			setTimeout(function(){ 

				TweenMax.to('.js-loader__sprite', 4, { opacity: 0, y: -500, ease: Back.easeInOut.config(1.7) });
				TweenMax.to('.js-loader__progress', 0.3, { width: 0, opacity: 0, ease: Back.easeInOut.config(1.7) });

				$('.js-loader').delay(1500).fadeOut(2000, function() {
					siteInit.gsapTimelineIntro();
					$('.js-loader').remove();
				});

			}, 3000);

		});

	},

	gsapTimelineIntro: function() {

		TweenMax.to('.story', 1, { opacity: 1 });
		TweenMax.to('.js-logo', 1, { y: 0, opacity: 1, ease: Expo.easeInOut });

		var index = 0;
		messages = ['It’s the night before Christmas...'+'<br/>'+'Santa’s leaving in a sleigh full of presents.', 'Definitely too full.'+'<br/>'+'Someone’s got to take advantage of that!', 'The Grinch shows up. Or maybe...'+'<br/>'+'Is that you, riding a rocket with a resting Grinch face?'],
		numMessages = messages.length;

		var main = new TimelineMax({
			onComplete: function(){ 
				$('.story-container').attr('data-story','story-end');
				$('.js-controller').fadeIn(2000 , function() {
					$('.js-story-container .story').remove();
				});
				TweenMax.to('.js-logo', 1, { y: -100, opacity: 0, ease: Expo.easeInOut });
				setTimeout(function(){
					$('.js-video').trigger('play');
				},500);
			}
		});

		setTimeout(function(){ $(body).trigger('completeStory01'); }, 1000); 

		function createTextDivs(){
			for(var i = 0; i < numMessages; i++){
				var textDiv = $('<h1/>').html(messages[i]).appendTo($('.story__intro'));
				var textSplit = new SplitText(textDiv, {type: 'chars, words'});
		
				main.staggerFrom(textSplit.chars, 0.4, { opacity: 0, ease: Expo.easeInOut }, 0.01)
					.staggerTo(textSplit.chars, 0.4, { delay: 3, opacity: 0, ease: Expo.easeInOut }, 0.01);

			}
		}

		createTextDivs();

		$('.js-skipintro').click(function(){
			TweenMax.to('.js-logo', 1, { y: -100, opacity: 0, ease: Expo.easeInOut });
			$(body).trigger('introSkipped');
			$('.story-container').addClass('story-skipped');
			$('.story-container').attr('data-story','story-end');
			$('.js-controller').fadeIn(2000);

			setTimeout(function(){
				$('.js-video').trigger('play');
			},500);

		});

		if($(window).width()<768){
			$('.js-video').get(0).pause();
			$('.js-video').remove();
		}

	},

	gsapTimelineGameover: function() {

		$(body).on('countDownEnd', function() {
			$('.js-current-score .js-count').text('0');
		});

		$('.js-ui-hit-box em').text();

		$(body).on('gameover', function() {

			$('.js-status-gameover').fadeIn();
   
			var timelineGameover = new TimelineMax({
				delay: 0.5, 
				onComplete: function(){ 
					scoreUpdate();
				}
			});

			var title = $('.js-splittext-status__title > div > div');
			var subtitle = $('.js-splittext-status__subtitle > div > div');
			var content = $('.js-status__content');
			var from_bottom = $('.js-from-bottom');
			var santa_from_bottom = $('.js-santa-from-bottom');

			timelineGameover
				.from(santa_from_bottom, 2, { y: 80, opacity: 0, ease: Back.easeInOut.config(1.7) })
				.staggerFrom(title, 0.4, { y: -80, opacity: 0, ease: Back.easeInOut.config(1.7) }, 0.1)
				.staggerFrom(subtitle, 0.4, { opacity: 0, ease: Expo.easeInOut },0.01,'-=0.5')
				.from(content, 0.4, { y: -100, opacity: 0, ease: Back.easeInOut.config(1.7) })
				.staggerFrom(from_bottom, 0.4, { y: -60, opacity: 0, ease: Back.easeInOut.config(1.7) }, 0.1);
  
			function scoreUpdate() {
  
				var currentScore = $('.js-ui-hit-box em').text();
				$('.js-current-score .js-count').text(currentScore);

				$('.js-count').each(function() {

					$(this).prop('Counter',0).animate({
						Counter: $(this).text()
					}, {
						duration: 2000,
						easing: 'swing',
						step: function(now) {
							$(this).text(Math.ceil(now));
						}
					});

				});
		
			}

		});

	},

	gameRecord: function() {

		var gameRecord = $('.game-ui__gamerecord');
		var currentGameRecord = localStorage.getItem('game_record');

		if (currentGameRecord === null) {

			$(gameRecord).addClass('hidden');

		} else {

			$(gameRecord).removeClass('hidden');

			$('.js-ui-game-record span em').text(currentGameRecord);

		}

		$(body).on('gameover', function() {

			$('.js-game-ui').addClass('game-over');

			setTimeout(function(){
				$(gameRecord).removeClass('hidden');
				var currentGameRecord = localStorage.getItem('game_record');
				$('.js-ui-game-record span em').text(currentGameRecord);
			}, 1000);

		});

	},

	gameTrigger: function() {

		var tl_score = new TimelineMax({
			pause: true,
			onComplete: function(){ 
				tl_score.reverse();
			}
		});
	
		tl_score.to('.js-ui-hit-box', 0.2, { scale: 1.88, textShadow: '-8px -8px 0 #0a106e,-2px -2px 0 #23c2f0,10px -10px 0 #124399,-2px 2px 0 #12479c,10px 10px 0 #1873ba', ease: Back.easeOut.config(1.7) });
		
		$(body).on('hitgiftbox', function() {
			tl_score.play();
		});

		$(body).on('hitbomb', function() {
			TweenMax.fromTo('.mask-bg .mask-bg--bomb', 2.5, { opacity: 1 }, { opacity: 0 });
		});

		// TRIGGER countDownEnd
		$(body).on('countDownEnd', function() {
			TweenMax.to('.game-ui-top', 1, { y: 0, opacity: 1, ease: Expo.easeInOut });
			TweenMax.to('.game-ui-bottom', 1, { y: 0, opacity: 1, ease: Expo.easeInOut });
			$('.js-controller, .js-story-container').remove();
		});

		// TRIGGER countDownStart
		$(body).on('countDownStart', function() {      
			$('.js-cursor-custom').hide();
			$('html').removeClass('cursor-none');
			$('.js-countdown').addClass('js-countdown-start');
			$('.js-story-container, .js-controller, .js-status').fadeOut();

			if (ga) ga('send', 'event', 'Countdown', 'Countdown-Start', 'Countdown Started');
		});

		// TRIGGER PAUSE
		$(body).on('pause', function() {
			$('.js-status-pause').show();
		});

		// TRIGGER CONTROLLER DISCONETTED
		$(body).on('controller-disconnected', function() {
			$('.js-status-pause').show();
		});

		// TRIGGER GAME OVER
		$(body).on('gameover', function() {
			TweenMax.to('.game-ui-top', 1, { y: -200, opacity: 0, ease: Expo.easeInOut });
			TweenMax.to('.game-ui-bottom', 1, { y: 200, opacity: 0, ease: Expo.easeInOut });

			if (ga) ga('send', 'event', 'Gameover', 'Game-Over', 'Game Over');
		});

		var tl_lifeup = new TimelineMax({
			pause: true,
			onComplete: function(){
				tl_lifeup.reverse();
			}
		});

		tl_lifeup.to('.js-ui-hit-cookie', 0.4, { opacity: 1, scale: 0.9, textShadow: '-8px -8px 0 #0a106e,-2px -2px 0 #23c2f0,10px -10px 0 #124399,-2px 2px 0 #12479c,10px 10px 0 #1873ba', ease: Back.easeOut.config(1.7) });

		$(body).on('hitcookie', function() {
			tl_lifeup.play();
			TweenMax.fromTo('.mask-bg .mask-bg--cookie', 2, { opacity: 1 }, { opacity: 0 });
		});

	},

	tilt: function() {

		$('.js-tilt').tilt({
			glare: true,
			maxGlare: .5,
			perspective: 1000,
		});

	},

	hoverSplitText: function() {

		var targets = document.querySelectorAll('.js-splittext--hover');

		for (i=0; i < targets.length; i++) {

			var tl = new TimelineMax({paused: true});
			var split = new SplitText(targets[i]);

			tl.staggerTo(split.chars, 0.3, {y: -3, opacity: 0, ease: Elastic.easeIn.config(0.4, 0.95)}, 0.03, 0);
			tl.staggerTo(split.chars, 0.3, {y: 0, opacity: 1, ease: Elastic.easeOut.config(0.4, 0.95)}, 0.03, 0.4);
			targets[i].anim = tl;  

			targets[i].addEventListener('mouseenter', function() {

				if(!this.anim.isActive()) {
					this.anim.play(0);
				}

			});
	
		}

	},

	// SOUNDTRACK
	soundtrack: function() {

		// INTRO
		var audioIntro = new Howl({
			src: ['../audio/intro.mp3'],
			autoplay: true,
			loop: true,
			volume: 0.2,
		});

		// BATTLE
		var audioBattle = new Howl({
			src: ['../audio/battle.mp3'],
			autoplay: false,
			loop: true,
			volume: 0.3,
		});

		// HOVER
		var audioHover = new Howl({
			src: ['../audio/hover.mp3'],
			autoplay: false,
			volume: 1,
		});

		// COUNTDOWN
		var audioCountdown = new Howl({
			src: ['../audio/countdown.mp3'],
			autoplay: false,
			volume: 0.1,
		});

		// GAME OVER
		var audioGameover = new Howl({
			src: ['../audio/gameover.mp3'],
			autoplay: false,
			loop: true,
			volume: 0.2,
		});

		$(body).on('countDownStart', function() {

			setTimeout(function(){
				audioCountdown.play();
			}, 600);

			audioIntro.stop();
			audioGameover.stop();
			audioBattle.play();

		});

		$(body).on('gameover', function() {

			$('.js-cursor-custom').show();
			$('html').addClass('cursor-none');

			audioBattle.stop();
			audioGameover.play();
	
		});

		var audioSoundtrack = false;

		$('.js-ui-audio').click(function(){

			if (!audioSoundtrack) {
				$(this).removeClass('play');
				Howler.volume(0);
				audioSoundtrack = true;
			} else {
				$(this).addClass('play'); 
				Howler.volume(1);
				audioSoundtrack = false;
			}
		});

		$('.js-hover-audio').on('mouseenter', function() {
			playAudio();
		});

		function playAudio() {  
			audioHover.play();
		}

	},

	share: function() { 

		// FACEBOOK
		var getWindowOptions = function() {
			var width = 500;
			var height = 450;
			var left = (window.innerWidth / 2) - (width / 2);
			var top = (window.innerHeight / 2) - (height / 2);

			return [
				'resizable,scrollbars,status',
				'height=' + height,
				'width=' + width,
				'left=' + left,
				'top=' + top,
			].join();
		};

		var fbBtn = document.querySelector('.js-facebook-share');
		var title = encodeURIComponent('Santa Cloud');
		var shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + location.href + '&title=' + title;
		fbBtn.href = shareUrl; // 1

		fbBtn.addEventListener('click', function(e) {
			e.preventDefault();
			var win = window.open(shareUrl, 'ShareOnFb', getWindowOptions());
			win.opener = null; // 2
		});

		// TWITTER
		var twitterShare = document.querySelector('[data-js="twitter-share"]');

		twitterShare.onclick = function(e) {

			e.preventDefault();
			var url = 'https://www.santacloud.io';
			var currentGameRecord = $('.js-ui-hit-box em').text();
			// var currentGameRecord = localStorage.getItem('game_record');
			var title  = 'Show off your inner Grinch! You stole ' +currentGameRecord+ ' presents!';

			var twitterWindow = window.open('http://twitter.com/share?url=' + url + '&text=' + title + '&', 'twitterwindow', 'height=450, width=550, top='+($(window).height()/2 - 225) +', left='+$(window).width()/2 +', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

			if(twitterWindow.focus) { twitterWindow.focus(); }
			return false;
	
		};

	},

	customCursor: function() {

		var cursor = {
			delay: 8,
			_x: 0,
			_y: 0,
			endX: (window.innerWidth / 2),
			endY: (window.innerHeight / 2),
			cursorVisible: true,
			cursorEnlarged: false,
			$dot: document.querySelector('.cursor-dot'),
			$outline: document.querySelector('.cursor-dot-outline'),
			init: function() {
				// Set up element sizes
				this.dotSize = this.$dot.offsetWidth;
				this.outlineSize = this.$outline.offsetWidth;
				this.setupEventListeners();
				this.animateDotOutline();
			},
			setupEventListeners: function() {
				var self = this;
				// Anchor hovering
				document.querySelectorAll('a').forEach(function(el) {
					el.addEventListener('mouseover', function() {
						self.cursorEnlarged = true;
						self.toggleCursorSize();
					});
					el.addEventListener('mouseout', function() {
						self.cursorEnlarged = false;
						self.toggleCursorSize();
					});
				});
				// Click events
				document.addEventListener('mousedown', function() {
					self.cursorEnlarged = true;
					self.toggleCursorSize();
				});
				document.addEventListener('mouseup', function() {
					self.cursorEnlarged = false;
					self.toggleCursorSize();
				});
	
				document.addEventListener('mousemove', function(e) {
					// Show the cursor
					self.cursorVisible = true;
					self.toggleCursorVisibility();

					// Position the dot
					self.endX = e.pageX;
					self.endY = e.pageY;
					self.$dot.style.top = self.endY + 'px';
					self.$dot.style.left = self.endX + 'px';
				});
				// Hide/show cursor
				document.addEventListener('mouseenter', function(e) {
					self.cursorVisible = true;
					self.toggleCursorVisibility();
					self.$dot.style.opacity = 1;
					self.$outline.style.opacity = 1;
				});
				document.addEventListener('mouseleave', function(e) {
					self.cursorVisible = true;
					self.toggleCursorVisibility();
					self.$dot.style.opacity = 0;
					self.$outline.style.opacity = 0;
				});
			},
			animateDotOutline: function() {
				var self = this;
				self._x += (self.endX - self._x) / self.delay;
				self._y += (self.endY - self._y) / self.delay;
				self.$outline.style.top = self._y + 'px';
				self.$outline.style.left = self._x + 'px';
				requestAnimationFrame(this.animateDotOutline.bind(self));
			},
			toggleCursorSize: function() {
				var self = this;
				if (self.cursorEnlarged) {
					self.$dot.style.transform = 'translate(-50%, -50%) scale(0.4)';
					self.$outline.style.transform = 'translate(-50%, -50%) scale(2)';
				} else {
					self.$dot.style.transform = 'translate(-50%, -50%) scale(1)';
					self.$outline.style.transform = 'translate(-50%, -50%) scale(1)';
				}
			},
			toggleCursorVisibility: function() {
				var self = this;
				if (self.cursorVisible) {
					self.$dot.style.opacity = 1;
					self.$outline.style.opacity = 1;
				} else {
					self.$dot.style.opacity = 0;
					self.$outline.style.opacity = 0;
				}
			}
		};

		cursor.init();

	}

};