console.clear();

// Three.js
var scene;
var camera;
var controls;
var renderer;

// Lights
var ambientLight;

// 3D models
var land;
var santa;
var tree;
var home;
var giftBox;
var bomb;
var grinch;
var snowMan;
var igloo;
var penguin;
var yeti;

//Bounding box
var bgift = new THREE.Box3();
var bbomb = new THREE.Box3();
var bgrinch = new THREE.Box3();

// Selectors
var $body = $('body');

// Pivot
var pivotTree;
var pivotHome;
var pivotsnowMan;
var pivotIgloo;
var pivotPenguin;
var pivotYeti;

// Deadline position
var deadline = 250;

// List of generated pivot with assigned 3D models
var treePivotList = [];
var homePivotList = [];
var snowManPivotList = [];
var iglooPivotList = [];
var penguinPivotList = [];
var yetiPivotList = [];

// List of active models
var giftBoxModelsList = [];
var bombModelsList = [];
var cookieModelsList = [];

// Player list for animations
var animationMixersGrinch = [];
var animationMixersSanta = [];

// Particles
var particles = [];

// Camera
var cameraFov = 60;
var cameraNear = 1;
var cameraFar = 2000;

var moveFlag = true;
var GPUTier = DetectGPU.getGPUTier();

// UI
var initialCountDownValue = 4;
var initialLife = 3;
var game = {
	level: 1,
	startSpeed: 0.001,
	boxVelocity: 500,
	life: initialLife,
	point: 0,
	bombLaunchVelocity: 1,
	giftBoxLaunchVelocity: 5,
	worldRotationVelocity: 1,
	grinchStartPositionY: 0,
	santaStartPositionY: 500,
	santaStartPositionZ: 900,
	santaLaunchPositionZ: -1400,
	gameStatus: 'intro', // intro, play, pause, stop, gameover, restart
	countDownValue: initialCountDownValue,
	countDownInterval: null,
	deviceConnected: false,
	collisionTolerance: 0,
	fogNear: 400,
	fogFar: 950,
	highPerformance:
		GPUTier.tier === 'GPU_MOBILE_TIER_1' ||
		GPUTier.tier === 'GPU_MOBILE_TIER_2' ||
		GPUTier.tier === 'GPU_MOBILE_TIER_3'
			? false
			: true
};

// Santa Position
var santaPathA = {
	x: game.highPerformance ? -550 : -150,
	y: 600,
	z: -1400
}; // Start position

var santaPathB = {
	x: game.highPerformance ? 550 : 150,
	y: 100,
	z: -1100
}; // End position

var sxRange = game.highPerformance ? -400 : -100;
var dxRange = game.highPerformance ? 400 : 100;

var levelDesigner = [
	{
		giftProb: 50,
		bombProb: 50,
		BonusProb: 0,
		elementsPerLevel: 5,
		launchFrequency: 1.1,
		moveFrequency: 1,
		elementVelocity: 700
	},
	{
		giftProb: 45,
		bombProb: 50,
		BonusProb: 5,
		elementsPerLevel: 10,
		launchFrequency: 1,
		moveFrequency: 1,
		elementVelocity: 700
	},
	{
		giftProb: 35,
		bombProb: 60,
		BonusProb: 5,
		elementsPerLevel: 20,
		launchFrequency: 0.5,
		moveFrequency: 1,
		elementVelocity: 800
	},
	{
		giftProb: 30,
		bombProb: 66,
		BonusProb: 4,
		elementsPerLevel: 35,
		launchFrequency: 0.3,
		moveFrequency: 1,
		elementVelocity: 850
	}
];
var spawnedElements = 0;

// Audio
var audioBomb = new Howl({
	src: ['../audio/bomb.mp3'],
	volume: 0.4
});
var audioGiftBox = new Howl({
	src: ['../audio/gift-box.mp3'],
	volume: 0.2
});
var audioCookie = new Howl({
	src: ['../audio/cookie.mp3'],
	volume: 0.2
});

// Loader FBX
var manager = new THREE.LoadingManager();
var loader = new THREE.FBXLoader(manager);

// Device parameters
var WIDTH = window.innerWidth;
var HEIGHT = window.innerHeight;
var aspectRatio = WIDTH / HEIGHT;
var mousePos = {
	x: 0,
	y: 0
};

navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

// Track of time
var clock = new THREE.Clock();
var lastSpawnTime = 0;
var lastTimeSanta = 0;

// TODO: Posizione santa per movimenti
var newPositionX;
var newPositionY;

// SCENE

function createScene() {
	// Scena 3D
	scene = new THREE.Scene();

	// Nebbia
	//scene.fog = new THREE.Fog(0xc1cecf, game.fogNear, game.fogFar);
	//scene.fog = new THREE.Fog(0xaee3db, 400, 950);

	// Camera
	camera = new THREE.PerspectiveCamera(cameraFov, aspectRatio, cameraNear, cameraFar);
	camera.position.set(0, 400, 500);

	// Renderer
	renderer = new THREE.WebGLRenderer({
		antialias: game.highPerformance,
		alpha: true
	});
	renderer.setPixelRatio(window.devicePixelRatio);
	renderer.setSize(window.innerWidth, window.innerHeight);
	renderer.shadowMap.enabled = game.highPerformance;
	renderer.shadowMap.type = THREE.PCFSoftShadowMap;
	//renderer.gammaFactor = 2.2;
	//renderer.gammaOutput = true;
	renderer.domElement.classList = 'three-canvas js-three-canvas';
	$('.js-scene').append(renderer.domElement);
}

// LOAD MODELS
function loadGiftBoxes() {
	for (var i = 0; i < 10; i++) {
		loader.load('../models/giftbox.fbx', function(object) {
			giftBox = object;
			giftBox.scale.set(0.6, 0.6, 0.6);
			giftBox.position.y = 0;
			giftBox.position.x = 0;
			giftBox.position.z = 0;
			giftBox.active = false;
			giftBox.children[0].material.color.setHex(Math.random() * 0xff0000 - 0xff0000);
			giftBox.children[1].material.color.setHex(Math.random() * 0xff0000 - 0xff0000);
			//bgift = new THREE.Box3().setFromObject(giftBox);
			giftBox.traverse(function(child) {
				if (child.isMesh) {
					child.castShadow = game.highPerformance;
					child.receiveShadow = game.highPerformance;
				}
			});
			giftBoxModelsList.push(giftBox);
			scene.add(giftBox);
		});
	}
}

function loadBombs() {

	// TODO: merge dei poligoni come https://medium.com/@joshmarinacci/procedural-geometry-trees-896cc06f54ce
	for (var i = 0; i < 10; i++) {

		var bomb = new THREE.Group();

		var bombSphereGeometry = new THREE.SphereGeometry(17, 10, 8);
		var bombSphereMaterial = new THREE.MeshLambertMaterial({
			color: 0x252525
		});
		var bombSphere = new THREE.Mesh(bombSphereGeometry, bombSphereMaterial);
		bombSphere.receiveShadow = true;
		bomb.add(bombSphere);

		var bombTopGeometry = new THREE.CylinderGeometry(6, 6, 4, 32);
		var bombTopMaterial = new THREE.MeshBasicMaterial({
			color: 0x252525
		});
		var bombTop = new THREE.Mesh(bombTopGeometry, bombTopMaterial);
		bombTop.position.y = 18;
		bomb.add(bombTop);

		var bombFuseGeometry = new THREE.CylinderGeometry(0.5, 0.5, 10, 7);
		var bombFuseMaterial = new THREE.MeshBasicMaterial({
			color: 0xffffff
		});
		var bombFuse = new THREE.Mesh(bombFuseGeometry, bombFuseMaterial);
		bombFuse.position.y = 25;
		bomb.add(bombFuse);

		var bombFlameGeometry = new THREE.TetrahedronGeometry(4, 1);
		var bombFlameMaterial = new THREE.MeshBasicMaterial({
			color: 0xdf960f
		});
		var bombFlame = new THREE.Mesh(bombFlameGeometry, bombFlameMaterial);
		bombFlame.position.y = 30;
		bomb.add(bombFlame);

		bomb.position.y = 0;
		bomb.position.z = deadline + 150;
		bomb.active = false;

		bombModelsList.push(bomb);
		scene.add(bomb);
	}
}

// ADD MODELS

function addLights() {
	
	hemisphereLight = new THREE.HemisphereLight(0xaaaaaa, 0x000000, 0.4);
	scene.add(hemisphereLight);
	
	ambientLight = new THREE.AmbientLight(0xddfeff, 0.5);
	scene.add(ambientLight);

	shadowLight = new THREE.DirectionalLight(0xffffff, 0.3);
	shadowLight.position.set(150, 350, 350);
	shadowLight.castShadow = true;
	shadowLight.shadow.camera.left = -400;
	shadowLight.shadow.camera.right = 400;
	shadowLight.shadow.camera.top = 400;
	shadowLight.shadow.camera.bottom = -400;
	shadowLight.shadow.camera.near = 1;
	shadowLight.shadow.camera.far = 1000;
	shadowLight.shadow.mapSize.width = game.highPerformance ? 4096 : 512;
	shadowLight.shadow.mapSize.height = game.highPerformance ? 4096 : 512;
	scene.add(shadowLight);

}
function loadSanta() {
	if (santa == null) {
		loader.load('../models/santa.fbx', function(object) {
			santa = object;
			santa.mixer = new THREE.AnimationMixer(santa);
			animationMixersSanta.push(santa.mixer);
			santa.scale.set(1.2, 1.2, 1.2);
			santa.position.y = game.santaStartPositionY;
			santa.position.z = game.santaStartPositionZ;
			santa.name = 'santa';

			var action = santa.mixer.clipAction(santa.animations[0]);
			action.play();

			santa.traverse(function(child) {
				if (child.isMesh) {
					child.castShadow = game.highPerformance;
					child.receiveShadow = game.highPerformance;
				}
			});
		});
	}
}

function addSanta() {
	if (santa !== null) {
		scene.add(santa);
	}
}

function loadGrinch() {
	if (grinch == null) {
		loader.load('../models/grinch.fbx', function(object) {
			grinch = object;
			grinch.mixer = new THREE.AnimationMixer(grinch);
			animationMixersGrinch.push(grinch.mixer);
			grinch.scale.set(0.2, 0.2, 0.2);
			grinch.position.y = game.grinchStartPositionY;
			grinch.position.z = deadline;
			var action = grinch.mixer.clipAction(grinch.animations[0]);
			action.play();
			grinch.traverse(function(child) {
				if (child.isMesh) {
					child.castShadow = game.highPerformance;
					child.receiveShadow = game.highPerformance;
				}
			});
			bgrinch = new THREE.Box3().setFromObject(grinch);
			var grinchBoundariesMin = bgrinch.min;
			var grinchBoundariesMax = bgrinch.max;
			bgrinch.set(
				{
					x: grinchBoundariesMin.x - game.collisionTolerance,
					y: grinchBoundariesMin.y - game.collisionTolerance,
					z: grinchBoundariesMin.z - game.collisionTolerance
				},
				{
					x: grinchBoundariesMax.x + game.collisionTolerance,
					y: grinchBoundariesMax.y + game.collisionTolerance,
					z: grinchBoundariesMax.z + game.collisionTolerance
				}
			);
		});
	}
}

function addGrinch() {
	if (grinch !== null) {
		scene.add(grinch);
	}
}


function addTree() {
	var numberTree = game.highPerformance ? 9 : 2;
	if (GPUTier.tier === 'GPU_MOBILE_TIER_3') {
		numberTree = 4;
	}

	for (var i = 0; i < numberTree; i++) {
		loader.load('../models/tree.fbx', function(object) {
			tree = object;
			tree.scale.set(0.6, 0.6, 0.6);
			tree.position.y = 180;
			tree.rotation.y = randomIntFromInterval(sxRange, dxRange);
			tree.traverse(function(child) {
				if (child.isMesh) {
					child.castShadow = game.highPerformance;
					child.receiveShadow = game.highPerformance;

					// TODO: cambiare materiale nel modello
					var oldMat = child.material;
					child.material = new THREE.MeshLambertMaterial({
						color: oldMat.color,
						map: oldMat.map
					});
				}
			});
			pivotTree = new THREE.Object3D();
			pivotTree.add(tree);
			pivotTree.position.x = randomIntFromInterval(sxRange, dxRange);
			pivotTree.rotation.x = randomIntFromInterval(sxRange, dxRange);
			scene.add(pivotTree);
			treePivotList.push(pivotTree);
		});
	}
}

function addsnowMan() {
	loader.load('../models/snowman.fbx', function(object) {
		snowMan = object;
		snowMan.scale.set(0.8, 0.8, 0.8);
		snowMan.position.y = 160;
		snowMan.rotation.y = 160;
		snowMan.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = game.highPerformance;
				child.receiveShadow = game.highPerformance;
			}
		});
		pivotsnowMan = new THREE.Object3D();
		pivotsnowMan.add(snowMan);
		pivotsnowMan.rotation.x = 200;
		pivotsnowMan.position.x = randomIntFromInterval(sxRange, dxRange);
		scene.add(pivotsnowMan);
		snowManPivotList.push(pivotsnowMan);
	});
}

function addYeti() {
	loader.load('../models/yeti.fbx', function(object) {
		yeti = object;
		yeti.scale.set(0.8, 0.8, 0.8);
		yeti.position.y = 160;
		yeti.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = game.highPerformance;
				child.receiveShadow = game.highPerformance;
			}
		});
		pivotYeti = new THREE.Object3D();
		pivotYeti.position.x = randomIntFromInterval(sxRange, dxRange);
		pivotYeti.add(yeti);
		scene.add(pivotYeti);
		yetiPivotList.push(pivotYeti);
	});
}

function addPenguin() {
	loader.load('../models/penguin.fbx', function(object) {
		penguin = object;
		penguin.scale.set(0.8, 0.8, 0.8);
		penguin.position.y = 170;
		penguin.position.x = 0;
		penguin.rotation.y = 160;
		penguin.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = game.highPerformance;
				child.receiveShadow = game.highPerformance;
			}
		});
		pivotPenguin = new THREE.Object3D();
		pivotPenguin.add(penguin);
		scene.add(pivotPenguin);
		penguinPivotList.push(pivotPenguin);
	});
}

function addIgloo() {
	loader.load('../models/igloo.fbx', function(object) {
		igloo = object;
		igloo.scale.set(0.7, 0.7, 0.7);
		igloo.position.y = 170;
		igloo.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = game.highPerformance;
				child.receiveShadow = game.highPerformance;
			}
		});
		pivotIgloo = new THREE.Object3D();
		pivotIgloo.add(igloo);
		pivotIgloo.position.x = randomIntFromInterval(sxRange, dxRange);
		pivotIgloo.rotation.x = randomIntFromInterval(sxRange, dxRange);
		pivotIgloo.rotation.y = THREE.Math.degToRad(180);
		scene.add(pivotIgloo);
		iglooPivotList.push(pivotIgloo);
	});
}

function addCookie() {
	loader.load('../models/cookie.fbx', function(object) {
		cookie = object;
		cookie.scale.set(5, 5, 5);
		cookie.position.y = 0;
		cookie.position.z = deadline + 150;
		cookie.active = false;
		//bbomb = new THREE.Box3().setFromObject(bomb);
		cookie.traverse(function(child) {
			if (child.isMesh) {
				child.castShadow = true;
				child.receiveShadow = true;
			}
		});
		cookieModelsList.push(cookie);
		scene.add(cookie);
	});
}

function loadHomes(n) {
	var num = n || 2;
	//console.log('loading %d', num);
	for (var i = 0; i < num; i++) {
		loader.load('../models/home.fbx', function(object) {
			home = object;
			home.scale.set(0.6, 0.6, 0.6);
			home.position.y = 110;
			//home.position.x = randomIntFromInterval(sxRange, dxRange);

			home.traverse(function(child) {
				if (child.isMesh) {
					child.castShadow = game.highPerformance;
					child.receiveShadow = game.highPerformance;
				}
			});

			pivotHome = new THREE.Object3D();
			pivotHome.add(home);
			pivotHome.position.x = randomIntFromInterval(sxRange, dxRange);
			pivotHome.rotation.x = randomIntFromInterval(sxRange, dxRange);
			pivotHome.rotation.y = THREE.Math.degToRad(270);
			scene.add(pivotHome);
			homePivotList.push(pivotHome);
		});
	}
}

function moveHomes() {
	for (var i = 0; i < homePivotList.length; i++) {
		var element = homePivotList[i];
		var r = randomIntFromInterval(150, 200);
		element.rotation.x = THREE.Math.degToRad(r);
		var xpos = randomIntFromInterval(sxRange + 100, dxRange - 100);
		element.position.x = xpos;
		element.rotation.y = THREE.Math.degToRad(randomIntFromInterval(260, 280));
		home.position.y = 110;
	}
}

function moveTrees(n) {
	var num = n || treePivotList.length / 2;
	for (var i = 0; i < num; i++) {
		var element = treePivotList[i];
		var r = randomIntFromInterval(160, 200);
		element.rotation.x = THREE.Math.degToRad(r);
		element.position.x = randomIntFromInterval(sxRange, dxRange);
	}
}

function moveIgloo(n) {
	for (var i = 0; i < iglooPivotList.length; i++) {
		var element = iglooPivotList[i];
		var r = randomIntFromInterval(270, 280);
		element.rotation.x = THREE.Math.degToRad(r);
		var xpos = randomIntFromInterval(sxRange + 100, dxRange - 100);
		element.position.x = xpos;
		igloo.position.y = 170;
		element.rotation.y = THREE.Math.degToRad(randomIntFromInterval(180, 200));
	}
}

function movePenguin() {
	for (var i = 0; i < penguinPivotList.length; i++) {
		var element = penguinPivotList[i];
		var r = randomIntFromInterval(270, 280);
		element.rotation.x = THREE.Math.degToRad(r);
		var xpos = randomIntFromInterval(sxRange + 100, dxRange - 100);
		element.position.x = xpos;
		penguin.position.y = 170;
	}
}

function moveYeti() {
	for (var i = 0; i < yetiPivotList.length; i++) {
		var element = yetiPivotList[i];
		var r = randomIntFromInterval(260, 280);
		element.rotation.x = THREE.Math.degToRad(r);
		var xpos = randomIntFromInterval(sxRange, dxRange);
		element.position.x = xpos;
		yeti.position.y = 160;
	}
}

Land = function() {
	var geom = new THREE.SphereGeometry(180, 20, 20, 40, 10);
	geom.applyMatrix(new THREE.Matrix4().makeRotationX(-Math.PI / 2));
	geom.applyMatrix(new THREE.Matrix4().makeRotationY(Math.PI / 2));
	geom.scale(4, 1, 1);
	geom.mergeVertices();
	var l = geom.vertices.length;

	this.waves = [];

	for (var i = 0; i < l; i++) {
		var v = geom.vertices[i];
		this.waves.push({
			y: v.y,
			x: v.x,
			z: v.z,
			ang: Math.random() * Math.PI * 2,
			amp: 5 + Math.random() * 25,
			speed: 0.016 + Math.random() * 0.032
		});
	}

	var mat = new THREE.MeshLambertMaterial({
		color: 0xffffff
	});

	this.mesh = new THREE.Mesh(geom, mat);
	this.mesh.receiveShadow = game.highPerformance;
};

Land.prototype.applyVerts = function() {
	var verts = this.mesh.geometry.vertices;
	var l = verts.length;
	for (var i = 0; i < l; i++) {
		var v = verts[i];
		var vprops = this.waves[i];
		v.x = vprops.x + Math.cos(vprops.ang) * vprops.amp;
		v.y = vprops.y + Math.sin(vprops.ang) * vprops.amp;
		vprops.ang = vprops.speed;
	}
};

function addLand() {
	land = new Land();
	land.applyVerts();
	//land.mesh.position.y = -220;
	scene.add(land.mesh);
}

function addSnow() {
	// Math
	var TO_RADIANS = Math.PI / 180;

	// Particelle neve
	Particle3D = function(material) {
		THREE.Sprite.call(this, material);

		//this.material = material instanceof Array ? material : [ material ];
		this.velocity = new THREE.Vector3(0, -8, 0);
		this.velocity.rotateX(randomIntFromInterval(-45, 45));
		this.velocity.rotateY(randomIntFromInterval(0, 360));
		this.gravity = new THREE.Vector3(0, 0, 0);
		this.drag = 1;
	};

	Particle3D.prototype = new THREE.Sprite();
	Particle3D.prototype.constructor = Particle3D;

	Particle3D.prototype.updatePhysics = function() {
		this.velocity.multiplyScalar(this.drag);
		this.velocity.add(this.gravity);
		this.position.add(this.velocity);
	};

	THREE.Vector3.prototype.rotateY = function(angle) {
		cosRY = Math.cos(angle * TO_RADIANS);
		sinRY = Math.sin(angle * TO_RADIANS);

		var tempz = this.z;
		var tempx = this.x;

		this.x = tempx * cosRY + tempz * sinRY;
		this.z = tempx * -sinRY + tempz * cosRY;
	};

	THREE.Vector3.prototype.rotateX = function(angle) {
		cosRY = Math.cos(angle * TO_RADIANS);
		sinRY = Math.sin(angle * TO_RADIANS);

		var tempz = this.z;
		var tempy = this.y;

		this.y = tempy * cosRY + tempz * sinRY;
		this.z = tempy * -sinRY + tempz * cosRY;
	};

	THREE.Vector3.prototype.rotateZ = function(angle) {
		cosRY = Math.cos(angle * TO_RADIANS);
		sinRY = Math.sin(angle * TO_RADIANS);

		var tempx = this.x;
		var tempy = this.y;

		this.y = tempy * cosRY + tempx * sinRY;
		this.x = tempy * -sinRY + tempx * cosRY;
	};

	// Neve
	var material = new THREE.SpriteMaterial({
		map: new THREE.TextureLoader().load('../img/snow.png')
	});

	for (var i = 0; i < 200; i++) {
		particle = new Particle3D(material);
		particle.position.x = Math.random() * 2000 - 1000;
		particle.position.y = Math.random() * 2000 - 1000;
		particle.position.z = Math.random() * 2000 - 1000;
		particle.scale.x = 8;
		particle.scale.y = 8;
		scene.add(particle);
		particles.push(particle);
	}
}

// CLONE LOADED MODELS

function cloneGiftBox(santaPositionX, santaPositionY, santaPositionZ) {
	var el = giftBoxModelsList[randomIntFromInterval(0, giftBoxModelsList.length - 1)];
	if (el.active == false) {
		el.position.y = santaPositionY + 50;
		el.position.x = santaPositionX;
		el.position.z = santaPositionZ + 400;
		el.active = true;
		return;
	} else {
		cloneGiftBox(santaPositionX, santaPositionY, santaPositionZ);
	}
}

function cloneBomb(santaPositionX, santaPositionY, santaPositionZ) {
	for (var i = 0; i < bombModelsList.length; i++) {
		var el = bombModelsList[i];
		if (el.active == false) {
			el.position.y = santaPositionY + 50;
			el.position.x = santaPositionX;
			el.position.z = santaPositionZ + 400;
			el.active = true;
			return;
		}
	}
}

function cloneCookie(santaPositionX, santaPositionY, santaPositionZ) {
	for (var i = 0; i < cookieModelsList.length; i++) {
		var el = cookieModelsList[i];
		if (el.active == false) {
			el.position.y = santaPositionY + 50;
			el.position.x = santaPositionX;
			el.position.z = santaPositionZ + 400;
			el.active = true;
			return;
		}
	}
}

// MOVE MODELS

$body.on('completeStory01', function() {
	addSanta();
});

$body.on('introSkipped', function() {
	addSanta();
});

function moveEnterSantaPosition() {
	santa.position.z += (game.santaLaunchPositionZ - santa.position.z) * 0.003;
}

function moveInitialGrichPosition() {
	grinch.position.y = game.grinchStartPositionY;
	grinch.position.z = deadline;
	grinch.rotation.x = 0;
}

function moveFlySantaPosition() {
	newPositionX = randomIntFromInterval(santaPathA.x, santaPathB.x);
	newPositionY = randomIntFromInterval(santaPathA.y, santaPathB.y);
}

function moveExitGrichPosition() {
	grinch.rotation.x -= (0.6 - grinch.rotation.x) * 0.04;
	grinch.position.y += (grinch.position.z - 300 - grinch.position.y) * 0.01;
	grinch.position.z += (grinch.position.z + 400 - grinch.position.z) * 0.01;
}

function moveSnow() {
	for (var i = 0; i < particles.length; i++) {
		var particle = particles[i];
		particle.updatePhysics();

		if (particle.position.y < -1000) {
			particle.position.y += 2000;
		}

		if (particle.position.x > 1000) {
			particle.position.x -= 2000;
		} else if (particle.position.x < -1000) {
			particle.position.x += 2000;
		}

		if (particle.position.z > 1000) {
			particle.position.z -= 2000;
		} else if (particle.position.z < -1000) {
			particle.position.z += 2000;
		}
	}
}

function moveGrinch(delta) {
	var bound = game.highPerformance ? 220 : 100;
	var targetX = normalize(mousePos.x, -1, 1, -bound, bound);
	var targetY = normalize(mousePos.y, -0.75, 0.75, 300, 510);
	grinch.position.x += (targetX - grinch.position.x) * 0.15;
	grinch.position.y += (targetY - grinch.position.y) * 0.11;
	grinch.position.z += (deadline - grinch.position.z) * delta * 2.8;
	grinch.rotation.z = (-targetX - grinch.position.x) * 0.00098; // - Target perchè opposto alla direzione del mouse
	//grinch.rotation.x = (targetY - grinch.position.y) * 0.00178;

}

function moveSanta() {
	if (santa != null) {
		santa.position.x += (newPositionX - santa.position.x) * 0.006;
		santa.position.y += (newPositionY - santa.position.y) * 0.002;
	}
}

function spawnElement(santaX, santaY, santaZ) {
	var n = randomIntFromInterval(0, 100);
	if (n <= levelDesigner[game.level - 1].giftProb) {
		// Se n ha un valore da 0 alla percentuale di giftProb
		//Spawn gift
		cloneGiftBox(santaX, santaY, santaZ);
	} else if (
		n > levelDesigner[game.level - 1].giftProb &&
		n <= levelDesigner[game.level - 1].giftProb + levelDesigner[game.level - 1].bombProb
	) {
		// Se n ha un valore da giftProb a giftProb + BombProb (esempio 80% e 10%, n dovrà essere tra 80 e 80+10)
		//spawn bomb
		cloneBomb(santaX, santaY, santaZ);
	} else {
		//spawn bonus/malus
		if (game.life < 3) {
			cloneCookie(santaX, santaY, santaZ);
		}
	}
}

function hideElement(element) {
	if (element != null) {
		element.position.z = 0;
		element.position.y = 0;
		element.position.x = 0;
		element.active = false;
	}
}

function moveElements(timestamp, delta) {

	bgrinch.setFromObject(grinch);

	if (spawnedElements === levelDesigner[game.level - 1].elementsPerLevel && game.level < levelDesigner.length) {
		game.level++;
		spawnedElements = 0;
	}

	if (timestamp - lastSpawnTime >= levelDesigner[game.level - 1].launchFrequency * 1000) {
		if (game.gameStatus == 'play') {
			lastSpawnTime = timestamp;
			spawnedElements++;
			if (santa != null) {
				spawnElement(santa.position.x, santa.position.y, santa.position.z);
			}
		}
	}

	if (timestamp - lastTimeSanta >= levelDesigner[game.level - 1].moveFrequency * 1000) {
		if (game.gameStatus == 'play') {
			lastTimeSanta = timestamp;
			if (santa != null) {
				moveFlySantaPosition();
			}
		}
	}


	for (var i = 0; i < giftBoxModelsList.length; i++) {
		var giftBoxItem = giftBoxModelsList[i];
		if (giftBoxItem.active == true) {
			giftBoxItem.rotation.x += delta * 1.8;
			giftBoxItem.rotation.y += delta * 0.8;
			//giftBoxItem.position.z += delta * 200.8;
			giftBoxItem.position.z += delta * levelDesigner[game.level - 1].elementVelocity;

			if (game.gameStatus == 'gameOver') {
				giftBoxItem.position.y += (0 - giftBoxItem.position.y) * 0.003;
			}

			if (giftBoxItem.position.z > deadline - 100) {
				bgift.setFromObject(giftBoxItem);

				if (bgrinch.intersectsBox(bgift)) {
					hideElement(giftBoxItem);
					onHitGiftBoxes();
				}
			}

			if (giftBoxItem.position.z > deadline + 150) {
				hideElement(giftBoxItem);
				//scene.remove(giftBoxItem);
				//giftBoxModelsList.splice(i, 1);
				//onMissedGiftBoxes();
			}
		}
	}

	for (var i = 0; i < bombModelsList.length; i++) {
		var bombItem = bombModelsList[i];
		if (bombItem.active == true) {
			bombItem.rotation.x += delta * 1.4;
			bombItem.rotation.y += delta * 0.4;
			//bombItem.position.z += delta * 200.8;
			bombItem.position.z += delta * levelDesigner[game.level - 1].elementVelocity;
			if (game.gameStatus == 'gameOver') {
				bombItem.position.y += (0 - bombItem.position.y) * 0.003;
			}

			if (bombItem.position.z > deadline - 100) {
				bbomb.setFromObject(bombItem);
				if (bgrinch.intersectsBox(bbomb)) {
					hideElement(bombItem);
					onHitBomb();
				}
			}

			if (bombItem.position.z > deadline + 150) {
				//scene.remove(bombItem);
				hideElement(bombItem);
				//bombModelsList.splice(i, 1);
			}
		}
	}

	for (var i = 0; i < cookieModelsList.length; i++) {
		var cookieItem = cookieModelsList[i];
		if (cookieItem.active == true) {
			cookieItem.rotation.x += delta * 1.4;
			cookieItem.rotation.y += delta * 0.4;
			//cookieItem.position.z += delta * 200.8;
			cookieItem.position.z += delta * levelDesigner[game.level - 1].elementVelocity;
			if (game.gameStatus == 'gameOver') {
				cookieItem.position.y += (0 - cookieItem.position.y) * 0.003;
			}

			if (cookieItem.position.z > deadline - 100) {
				bbomb.setFromObject(cookieItem);
				if (bgrinch.intersectsBox(bbomb)) {
					hideElement(cookieItem);
					onHitCookie();
				}
			}

			if (cookieItem.position.z > deadline + 150) {
				//scene.remove(bombItem);
				hideElement(cookieItem);
				//bombModelsList.splice(i, 1);
			}
		}
	}

}

function rotateLandWithElements(delta) {
	for (var i = 0, il = treePivotList.length; i < il; i++) {
		var treeItem = treePivotList[i];
		treeItem.rotation.x += delta * game.worldRotationVelocity;
	}

	for (var i = 0, il = homePivotList.length; i < il; i++) {
		var homeItem = homePivotList[i];
		homeItem.rotation.x += delta * game.worldRotationVelocity;
	}

	for (var i = 0, il = snowManPivotList.length; i < il; i++) {
		var snowManItem = snowManPivotList[i];
		snowManItem.rotation.x += delta * game.worldRotationVelocity;
	}

	for (var i = 0, il = iglooPivotList.length; i < il; i++) {
		var iglooItem = iglooPivotList[i];
		iglooItem.rotation.x += delta * game.worldRotationVelocity;
	}

	for (var i = 0, il = penguinPivotList.length; i < il; i++) {
		var penguinItem = penguinPivotList[i];
		penguinItem.rotation.x += delta * game.worldRotationVelocity;
	}

	for (var i = 0, il = yetiPivotList.length; i < il; i++) {
		var yetiItem = yetiPivotList[i];
		yetiItem.rotation.x += delta * game.worldRotationVelocity;
	}

	if (land != null) {
		land.mesh.rotation.x += delta * game.worldRotationVelocity;
		//land.rotation x += .005;
	}
	var rotationLand = Math.floor(THREE.Math.radToDeg(land.mesh.rotation.x) % 360);

	if (rotationLand >= 300 && rotationLand <= 330) {
		if (game.gameStatus == 'play' && moveFlag) {
			moveFlag = false;
			var coin = randomIntFromInterval(0, 1);
			if (coin == 1) {
				moveHomes();
				hideElement(igloo);
			} else {
				moveIgloo();
				hideElement(home);
			}
			coin = randomIntFromInterval(0, 10);
			if (coin <= 2) {
				moveYeti();
			} else {
				hideElement(yeti);
			}
			coin = randomIntFromInterval(0, 10);
			if (coin <= 4) {
				moveYeti();
			} else {
				hideElement(yeti);
			}
			movePenguin();
		}
	} else {
		moveFlag = true;
	}
}

function play3dModelsAnimations(delta) {
	if (animationMixersGrinch.length > 0) {
		for (var i = 0; i < animationMixersGrinch.length; i++) {
			animationMixersGrinch[i].update(delta);
		}
	}

	if (animationMixersSanta.length > 0) {
		for (var i = 0; i < animationMixersSanta.length; i++) {
			animationMixersSanta[i].update(delta);
		}
	}
}

// HANDLE

function handleMouseMove(event) {
	if (localStorage.getItem('controller') == 'mouse') {
		var tx = -1 + (event.clientX / WIDTH) * 2;
		var ty = 1 - (event.clientY / HEIGHT) * 2;

		mousePos = {
			x: tx,
			y: ty
		};
	}
}

function handleTouchMove(event) {
	if (localStorage.getItem('controller') == 'mouse') {
		event.preventDefault();
		var tx = -1 + (event.touches[0].pageX / WIDTH) * 2;
		var ty = 1 - (event.touches[0].pageY / HEIGHT) * 2;
		mousePos = {
			x: tx,
			y: ty
		};
	}
}

function handleDeviceMove(event) {
	var gyroX, gyroY;
	if (event.gyro) {
		gyroX = event.gyro.x;
		gyroY = event.gyro.y;
		var tx = Math.round(event.gyro.x * 10000) / 10000;
		var ty = Math.round(event.gyro.y * 10000) / 10000;
	} else {
		gyroX = -event.gamma;
		gyroY = event.beta;
		var tx = normalize(THREE.Math.degToRad(gyroX), -1, 1, -180, 180);
		var ty = normalize(THREE.Math.degToRad(gyroY), -1, 1, -50, 50);
	}

	mousePos = {
		x: tx,
		y: ty
	};
}

function handleWindowResize() {
	HEIGHT = window.innerHeight;
	WIDTH = window.innerWidth;
	renderer.setSize(WIDTH, HEIGHT);
	camera.aspect = WIDTH / HEIGHT;
	camera.updateProjectionMatrix();
}

$('.js-select-controller').on('click', function(e) {
	e.preventDefault();

	if ($(this).data('controller') == 'mouse') {
		localStorage.setItem('controller', 'mouse');
		$('.js-controller-select').fadeOut();
		countDown();
	} else if ($(this).data('controller') == 'device') {
		$('.js-controller-select').hide();
		$('.js-controller-session').show();
		localStorage.setItem('controller', 'device');
		$('.js-tutorial').show();
	}

	addGrinch();
});

$('.js-status__restart').on('click', function(e) {
	e.preventDefault();
	if (!e.detail || e.detail == 1) {
		//activate on first click only to avoid hiding again on multiple clicks
		// It will execute only once on multiple clicks
		restartGame();
	}
});

// UTILITY

function randomFloatFrom2Value(min, max) {
	var random = Math.random();
	if (random <= 0.5) {
		random = min;
	} else {
		random = max;
	}
	return random;
}

function randomIntFromInterval(min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomFloatFromInterval(min, max) {
	return Math.random() * (max - min + 1) + min;
}

function normalize(v, vmin, vmax, tmin, tmax) {
	var nv = Math.max(Math.min(v, vmax), vmin);
	var dv = vmax - vmin;
	var pc = (nv - vmin) / dv;
	var dt = tmax - tmin;
	var tv = tmin + pc * dt;
	return tv;
}

function updateLife(life) {
	game.life = life ? life : game.life;
	var $el = $('.js-ui-life');
	$el.attr('data-life', game.life);
	$el.find('em').text(game.life);
}

// UI

function gameUi() {
	updateLife();

	var audioTransport = true;
	$('.js-ui-play').on('click', function() {
		if (audioTransport) {
			$('.js-ui-play').addClass('played');
			pauseGame();
			audioTransport = false;
		} else {
			$('.js-ui-play').removeClass('played');
			startGame();
			audioTransport = true;
		}
	});

	// $('.js-ui-pause').on('click', function() {
	// 	pauseGame();
	// });

	$('.js-ui-debug').on('click', function() {
		// Orbit controls
		controls = new THREE.OrbitControls(camera);
		controls.update();

		// Piano a griglia
		var plane = new THREE.GridHelper(1000, 10);
		scene.add(plane);
	});

	$('.js-ui-full-screen').on('click', function() {
		if (
			(document.fullScreenElement && document.fullScreenElement !== null) ||
			(!document.mozFullScreen && !document.webkitIsFullScreen)
		) {
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	});
}

function onMissedGiftBoxes() {
	$el.html(parseInt($el.html(), 10) + 1);
	updateUiStatus('missedgiftbox');
}

function onHitGiftBoxes() {
	$el = $('.game-ui__hit-box').find('em');
	$el.html(parseInt($el.html(), 10) + 1);
	game.point = game.point + 1;
	audioGiftBox.currentTime = 0;
	audioGiftBox.play();
	updateUiStatus('hitgiftbox');
}

function resetPoints(point) {
	spawnedElements = 0;
	var gamePoint = point ? point : 0;
	$el = $('.game-ui__hit-box').find('em');
	$el.html(gamePoint);
}

function onHitBomb() {
	audioBomb.currentTime = 0;
	audioBomb.play();
	updateUiStatus('hitbomb');
	game.life = game.life - 1;
	ambientLight.intensity = 2;
	grinch.position.z += 50;

	if (navigator.vibrate) {
		navigator.vibrate(100);
	}

	if (game.life == 0) {
		updateUiStatus('gameover');
		game.gameStatus = 'gameOver';
		game.level = 1;

		if (localStorage.getItem('game_record') === null) {
			localStorage.setItem('game_record', game.point);
		} else if (game.point > localStorage.getItem('game_record')) {
			localStorage.setItem('game_record', game.point);
		}
	}
	grinchBounce = true;
	particles.visible = true;
	updateLife();
}

function onHitCookie() {
	if (game.life < 3) {
		audioCookie.currentTime = 0;
		audioCookie.play();
		ambientLight.intensity = 2;
		updateUiStatus('hitcookie');
		game.life = game.life + 1;
		updateLife(game.life);
	}
}

function countDown() {
	updateUiStatus('countDownStart');
	var $el = $('.js-countdown');
	var $text = $el.find('.countdown__timer');
	$el.show();

	game.countDownInterval = setInterval(function() {
		game.countDownValue--;
		$text.text(game.countDownValue);

		if (game.countDownValue <= 0) {
			updateUiStatus('countDownEnd');
			clearInterval(game.countDownInterval);
			game.countDownInterval = null;
			$el.hide();
			$text.text('');
			startGame();
			game.countDownValue = initialCountDownValue;
		}
	}, 1000);
}

function loop(timestamp) {
	if (game.gameStatus != 'pause') {
		var delta = clock.getDelta();

		ambientLight.intensity += (0.5 - ambientLight.intensity) * delta * 2.8;

		if (game.gameStatus == 'play') {
			moveElements(timestamp, delta);
			moveGrinch(delta);
			moveSanta();
		}

		if (game.gameStatus == 'gameOver') {
			moveElements(timestamp, delta);
			moveExitGrichPosition();
		}

		if (game.gameStatus == 'restart') {
			moveElements(timestamp, delta);
		}

		if (scene.getObjectByName('santa')) {
			moveEnterSantaPosition();
		}
		rotateLandWithElements(delta);
		moveSnow();
		play3dModelsAnimations(delta);
	}

	renderer.render(scene, camera);
	requestAnimationFrame(loop);
}

// Init project
function initScene() {
	if (WEBGL.isWebGLAvailable()) {
		createScene();
		addLights();
		gameUi();
		addLand();
		loadSanta();
		loadGrinch();
		//addOldLand();
		if (game.highPerformance) {
			addSnow();
			addsnowMan();
			addIgloo();
			addPenguin();
			addYeti();
			loadHomes(1);
		}
		addTree();
		createSession();
		loadGiftBoxes();
		loadBombs();
		addCookie();
	} else {
		var warning = WEBGL.getWebGLErrorMessage();
	}
}

// Models loaded
manager.onLoad = function() {
	loop();
	updateUiStatus('managerLoaded');
};

manager.onProgress = function(url, itemsLoaded, itemsTotal) {
	var progressTotal = (itemsLoaded * 100) / itemsTotal;
	$('.js-loader__progress > div > div').css({
		width: progressTotal + '%'
	});
};

function updateUiStatus(message) {
	$body.trigger(message);
}

function controllerDisconnected() {
	clearInterval(game.countDownInterval);
	game.countDownInterval = null;
}

function createSession() {
	// Socket
	var socket = io.connect();

	socket.on('connect', function() {
		socket.emit('create-session');

		socket.on('initial-state', function(data) {
			$('.js-controlle-session-id').append(data.session);
			$('.js-controlle-qrcode').qrcode({
				//background: '#ffffff',
				//foreground: '#000000',
				text:
					location.protocol +
					'//' +
					window.document.location.host +
					'/controller/?sessionID=' +
					data.session +
					'&utm_source=qrcode&utm_medium=pagina&utm_campaign=SantaClaudio'
			});
		});

		socket.on('connection-established', function(data) {
			console.log('connection-established', game.gameStatus);
			if (game.gameStatus !== 'gameOver') {
				$('.js-controller-session').hide();
				$('.js-controlle-session-id').html('CONNECTED');
				$('.js-tutorial').hide();
				$('.js-status').hide();
				countDown(game.countDownValue);
			}
			game.deviceConnected = true;
		});

		socket.on('mobile-receive', function(data) {
			//console.log(data);
			handleDeviceMove(data);
		});

		// FAKE RICEZIONE MOBILE
		socket.on('desktop-receive', function(data) {
			//console.log('receive', data);
		});

		socket.on('controller-disconnected', function() {
			game.deviceConnected = false;
			controllerDisconnected();
			console.log('disconnesso', game.gameStatus);
			updateUiStatus('controller-disconnected');
			if (game.gameStatus !== 'gameOver') {
				pauseGame();
			}
		});

		$body.on('hitgiftbox', function() {
			socket.emit('desktop-send', {
				point: 5
			});
		});
	});
}

function startGame() {
	console.log('startGame', game.gameStatus);
	game.gameStatus = 'play';
	$('.js-status').hide();
	$('.js-game-ui').show();
}

function pauseGame() {
	console.log('pauseGame', game.gameStatus);
	game.gameStatus = 'pause';
	updateUiStatus('pause');
	clearInterval(game.countDownInterval);
}

function restartGame() {
	console.log('restartGame', game.gameStatus);
	game.gameStatus = 'restart';
	updateUiStatus('restart');
	updateLife(initialLife);
	resetPoints(0);
	moveInitialGrichPosition();
	countDown(game.countDownValue);
}

// Il renderer viene chiamato al resize
window.addEventListener('load', initScene, false);
window.addEventListener('resize', handleWindowResize, false);
document.addEventListener('mousemove', handleMouseMove, false);
document.addEventListener('touchmove', handleTouchMove, false);
